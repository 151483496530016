<template>
    <div>
      <!-- fd-wrap -->
      <div class="fd-app-wrap">
        <div class="container">
          <!-- breadcrumb -->
          <nav>
            <ul class="fd-app-breadcrumb fast-menu-lives-aluno-fast">
              <li class="fd-app-breadcrumb-item">
                <a
                  href="/minhas-plataformas"
                  class="aluno_font_color"
                  @click.prevent="$router.push('/minhas-plataformas')"
                >Minhas Plataformas</a>
              </li>
              <li class="fd-app-breadcrumb-item">
                <a
                  :href="'/plataforma/' + $route.params.id_plataforma"
                  class="aluno_font_color"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
                >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
              </li>
              <li class="fd-app-breadcrumb-item">
                <a
                  class="aluno_font_color"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
                >Modo aluno</a>
              </li>
              <li class="fd-app-breadcrumb-item active">
                <a href="#">Meus Boletos</a>
              </li>
            </ul>
            <ul class="fd-app-breadcrumb d-none fast-menu-lives-aluno-iuea">
              <li class="fd-app-breadcrumb-item">
                <a
                  class="aluno_font_color"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
                >Menu principal</a>
              </li>
              <li class="fd-app-breadcrumb-item active">
                <a
                  class="aluno_font_color"
                  href="#"
                >Meu mural</a>
              </li>
            </ul>
          </nav>
          <!-- /breadcrumb -->
        </div>
        <div class="fd-mode-ead-content mt-0">
          <div class="container">
            <div class="mt-3 mb-4">
              <a
                class="btn-novo btn-red-hollow-novo fast-iuea-ml-20"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >
                <b-icon-arrow-return-left /> Voltar
              </a>
            </div>
            <!--fd-app-welcome-novo-->
            <section class="fd-app-welcome-novo text-left fast-plataforma-iuea-hide">
              <h2 class="aluno_font_color mb-0">
                Meus 
                <span>Boletos</span>
              </h2>
              <p class="aluno_font_color my-0">
                Acesse todos os seus boletos
              </p>
              <div>
                <img
                  :src="require('@/assets/images/separador.png')"
                >
              </div>
            </section>
            <!--/fd-app-welcome-novo-->
            <div class="row pl-3">
              <div class="col-12 text-right mb-3">
                <button v-if="fastNavegacao === 'Pendentes'" class="btn btn-sm btn-outline-primary rounded" @click.prevent="alteraNavegacao()">Ver Pagos</button>
                <button v-if="fastNavegacao === 'Pagos'" class="btn btn-sm btn-outline-primary rounded"  @click.prevent="alteraNavegacao()">Ver Pendentes</button>
              </div>
              <div class="list-group col-12">
                <a v-if="fastNavegacao === 'Pendentes'" v-for="boleto in fastBoletosAluno.boletosPendentes" :key="boleto.id" href="#" class="fd-app-meus-boletos-item bootstrap-cursor list-group-item list-group-item-action flex-column align-items-start mb-4" :class="{ 'active': boleto.id === 0 }">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1"><small>Vencimento:</small> {{formataDataT(boleto.data_vencimento)}}</h5>
                    <span>R$ {{formataPreco(boleto.valor_parcela)}}</span>
                  </div>
                  <div class="d-flex w-100 justify-content-between pt-3">
                    <small>Parcela {{boleto.parcela}}/{{boleto.nr_parcelas}} - Invoice {{boleto.id_invoice}} - Pagamento Pendente</small>
                    <div v-if="boleto.path_boleto" class="text-nowrap">
                        <button title="ABRIR BOLETO" class="btn btn-sm rounded btn-outline-primary-custom mr-2 mb-2" :class="{ 'btn-outline-light-custom': boleto.id === 0 }">
                          <a :href="boleto.path_boleto" target="_blank" class="text-capitalize">
                            <b-icon icon="file-earmark-pdf" font-scale="1.3" ></b-icon>
                            Ver
                          </a>
                        </button>
                        <button title="COPIAR URL BOLETO" href="#" target="_blank" class="btn btn-sm rounded btn-outline-primary-custom btn-custom-hover text-capitalize mb-2" :class="{ 'btn-outline-light-custom': boleto.id === 0 }">
                          <a href="#" class="text-capitalize" @click.prevent="copyURLBoleto(boleto.path_boleto); exibeToasty('Boleto copiado para a Área de Transferência!','success')">
                            <b-icon icon="back" font-scale="1.3"></b-icon>
                            Copiar
                          </a>
                        </button>
                    </div>
                    <div v-else class="text-nowrap">
                      <button v-if="fastBoletosAluno.loading" class="btn btn-sm rounded btn-secondary">Gerando boleto...</button>
                      <button v-else class="btn btn-sm rounded btn-primary" @click="gerarBoletoComissaoManual(boleto)">Gerar boleto</button>
                    </div>
                  </div>
                </a>
                <span class="text-center my-3" v-if="fastNavegacao === 'Pendentes' && !fastBoletosAluno.boletosPendentes.length">Nenhum boleto pendente</span>
                <a v-if="fastNavegacao === 'Pagos'" v-for="boleto in fastBoletosAluno.boletosPagos" :key="boleto.id" href="#" class="fd-app-meus-boletos-item bootstrap-cursor list-group-item list-group-item-action flex-column align-items-start mb-4" :class="{ 'active': boleto.id === 0 }">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1"><small>Vencimento:</small> {{formataDataT(boleto.data_vencimento)}}</h5>
                    <small>R$ {{formataPreco(boleto.valor_parcela)}}</small>
                  </div>
                  <div class="d-flex w-100 justify-content-between pt-3">
                    <small>Parcela {{boleto.parcela}}/{{boleto.nr_parcelas}} - Invoice {{boleto.id_invoice}} - Pago em {{ formataDataT(boleto.data_pagamento) }}</small>
                    <h5>Pago <b-icon icon="check-circle" aria-hidden="true" font-scale="1"></b-icon></h5>
                  </div>
                </a>
                <span class="text-center my-3" v-if="fastNavegacao === 'Pagos' && !fastBoletosAluno.boletosPagos.length">Nenhum boleto pago</span>
              </div>

            </div>

          <!--modals-->
            <modal
              name="modalErrosRequisicao"
              :shift-y="0.1"
              height="auto"
              :width="modalWidthMd"
              :scrollable="true"
              :adaptative="true"
            >
              <div class="row p-4">
                <div class="col-10 pl-4 pr-4">
                  <h4>Erros encontrados</h4>
                </div>
                <div class="col-2 pl-4 pr-4 text-right">
                  <a
                    class="btn btn-secondary"
                    href="#"
                    @click.prevent="hideModal('modalErrosRequisicao')"
                  >
                    <b-icon-arrow-return-left />
                  </a>
                </div>
                <div class="col-12 p-4">
                  <div class="row">
                    <div class="col-12 table-responsive">  
                      <table class="table table-sm table-striped">
                        <thead class="thead-dark">
                          <tr>
                            <th>Nº</th>
                            <th>Descrição do erro</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody v-if="fastErros.length">
                          <tr v-for="(erro, index) in fastErros" :key="index">
                            <td>{{index + 1}}</td>
                            <td>{{erro}}</td>
                            <td></td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="3">Erro desconhecido. Contate um administrador.</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                  <div class="row mt-4">
                    <div class="col-12">
                      <button class="btn btn-secondary mr-2" @click="hideModal('modalErrosRequisicao')">
                        Fechar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </modal>
          <!--/modals-->
          </div>
        </div>
      </div>
      <!-- /fd-wrap -->
    </div>
  </template>
  
  <script>
  import { settings } from "@/config";
  import methods from "@/methods";
  
  export default {
    name: "HomeInternoAluno",
    mixins: [methods],
    data: function () {
      return {
        //#region variáveis gerais
        modalWidthSm: this.isMobile() ? "80%" : "30%",
        modalWidthMd: this.isMobile() ? "90%" : "50%",
        modalWidthLg: this.isMobile() ? "100%" : "90%", 
        fastTemplate: settings.fastTemplate,
        // Mural
        fastBoletosAluno: {
          boletosPendentes: [],
          boletosPagos: [],
          loading: false
          
        },
        fastNavegacao: 'Pendentes',
        fastErros: []
        
      };
    },
    mounted: function () {
      if (this.$route.params.id_plataforma) {
        this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
          .then(() => {
            this.getBoletosAluno();
          })
          .catch((e) => {
            this.exibeToasty(e, "error");
            this.$store.state.fastCarregando = false;
          });
      }
    },
    methods: {
        async getBoletosAluno(){       
            this.$store.state.fastCarregando = true;

             this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista_contas_receber_aluno", `IdPlataforma=${this.$route.params.id_plataforma}&IdPessoaAluno=${this.$store.state.fastPermissoes.id_pessoa}`)

            .then(res => {
            console.log("getBoletosAluno", res)
            if (res.length) {

                res.sort((a, b) => {
                  return (a.data_vencimento > b.data_vencimento ? 1 : -1);
                })

                const tempBoletosPendetes = res.filter(item => !item.cancelado && !item.pago);
                const tempBoletosPagos = res.filter(item => !item.cancelado && item.pago);

                this.fastBoletosAluno.boletosPendentes = tempBoletosPendetes.map((item, index) => {
                    return { ...item, id: index };
                });

                this.fastBoletosAluno.boletosPagos = tempBoletosPagos.map((item, index) => {
                    return { ...item, id: index };
                });
                
            }
            else {
              this.fastBoletosAluno.boleto = []
            }
    
            this.$store.state.fastCarregando = false;
            }).catch(e => {
              console.log(e)
              this.exibeToasty(e, "error")
              this.$store.state.fastCarregando = false;
            })
        },
        gerarBoletoComissaoManual(boleto){
          this.fastBoletosAluno.loading = true
          const obj = {
            id_plataforma: boleto.id_plataforma,
            id_invoice: boleto.id_invoice,
            parcela: boleto.parcela
          }
          this.promisePostFastApi(obj, "api/fast_tesouraria_invoice_parcela/gera_boleto_comissao_aluno")
            .then(res => {
              console.log("gera_boleto_comissao", res)
              if (res.length){
                const json = JSON.parse(res)            
                if (json.status) {
                  this.exibeToasty("Boleto gerado com sucesso", "success")
                  this.exibeToasty("Email enviado ao destinatário", "success")
                  boleto.path_boleto = json.path_boleto
                } else {
                  if (json.description.split(",").length) {
                    this.fastErros = json.description.split(",")
                  } else {
                    this.fastErros.push(json.description)
                    console.log("this.fastErros", this.fastErros)
                  }            
                  this.showModal('modalErrosRequisicao')
                }
              }
              this.fastBoletosAluno.loading = false        
            }).catch((e) => {
              this.exibeToasty(e, "error")
              this.fastBoletosAluno.loading = false
            })
        },
        alteraNavegacao(){

          this.$store.state.fastCarregando = true;
          this.fastNavegacao === 'Pendentes' ?  this.fastNavegacao = 'Pagos' : this.fastNavegacao = 'Pendentes'
          this.$store.state.fastCarregando = false;
        },
        copyURLBoleto(link) {
            const el = document.createElement('textarea');  
            el.value = link;                                 
            el.setAttribute('readonly', '');                
            el.style.position = 'absolute';                     
            el.style.left = '-9999px';                      
            document.body.appendChild(el);                  
            const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;                                    
            el.select();                                    
            document.execCommand('copy');                   
            document.body.removeChild(el);                  
            if (selected) {                                 
                document.getSelection().removeAllRanges();    
                document.getSelection().addRange(selected);   
            }
        },
    },
  };
  </script>
  
  <style scope>
  .bootstrap-cursor {
    cursor: default
  }
  .btn.btn-outline-primary-custom {
    background-color: transparent;
    color: #007bff;
    border-color: #007bff;
    box-shadow: none;
    outline: none;
  }
  .btn.btn-outline-primary-custom:hover {
    color: #003268;
    border-color: #003268;
  }

  .btn.btn-outline-primary-custom:hover a, .btn.btn-outline-primary-custom:hover .b-icon {
    color: #003268;
    text-decoration: none;
  }
  .btn.btn-outline-light-custom {
    background-color: transparent;
    color: #e9e9e9;
    border-color: #e9e9e9;
    box-shadow: none;
    outline: none;
  }
  .btn.btn-outline-light-custom:hover {
    color: #003268;
    border-color: #003268;
  }

  .btn.btn-outline-light-custom a, .btn.btn-outline-light-custom .b-icon {
    color: #e9e9e9;
    text-decoration: none;
  }

  .btn.btn-outline-light-custom:hover a, .btn.btn-outline-light-custom:hover .b-icon {
    color: #003268;
    text-decoration: none;
  }

  .fd-app-meus-boletos-item{
    border-radius: 10px;
    box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.30);
  }


  </style>
  